import React from 'react'
import './contact.css'
import formImg from './assets/danger-ahead.png'
import Faq from '../home/faq/Faq'
import InfoBar from '../home/info/InfoBar'
import ContactSchema from '../../schema/ContactSchema'

const Contact = () => {
  return (
    <div className='klt_contact'>
        {/* Hero */}
      <div className='klt_contact_row'>
            <div className='klt_contact_col'>
                <h1 className='klt_contact_text'>
                    CONTACT US
                </h1>
                <h3>
                    Contact Information
                </h3>
                <ContactSchema />
            </div>
        </div>
        {/* Contact Form */}
        <div className='contact_sup klt_contact_row'>
            <div className='klt_contact_col contact_sup_form'>
                <p>Write us a message</p>
                <form>
                    <legend>
                        <label htmlFor="name" >Your name</label>
                        <input type='text' name='name' required />
                    </legend>
                    <legend>
                        <label htmlFor="email" >Your email</label>
                        <input type='email' name='email' required />
                    </legend>
                    <legend>
                        <label htmlFor="name" >Your name</label>
                        <textarea name='message' ></textarea>
                    </legend>
                    <button className='klt_form_submit' type='submit' >Submit</button>
                </form>
            </div>
            <div className='klt_contact_col'>
                <img src={formImg} alt='warning sign' />
            </div>
        </div>
        <InfoBar />
        {/* Faq */}
        <Faq />
        {/* CTA */}
        <InfoBar />
    </div>
  )
}

export default Contact
