import React from 'react'
import towImg from "./assets/towed_vehicle.png"
import "./about.css"
import InfoBar from '../home/info/InfoBar'
import image_2 from '../../pages/home/about/assets/loose-tyre.png'
import AboutSchema from '../../schema/AboutSchema'

const About = () => {
  return (
    <div className='klt_aboutus'>
        {/* Hero */}
        <div className='klt_contact_row'>
            <div className='klt_contact_col'>
                <h1 className='klt_contact_text'>
                    ABOUT US
                </h1>
                <AboutSchema />
                <h3 className='kl_hero_text'>
                    We are a leading towing company
                </h3>
            </div>
        </div>
        <div className='klt_contact_row'>
            <img className='klt_about_img' src={towImg} alt='towed_vehicle' />
        </div>
        <InfoBar />
        <div className='klt_aboutUs_row'>
            <div className='klt_testimonial_col'>
                <h2 className='klt_colored_text'>
                    WHY US
                </h2>
                <h3>
                    We provide high quality <br />services
                </h3>
                <p>
                    We are a well-equipped towing service available to help with <br/>towing a car 24/7 we provide a fast, affordable, friendly, and <br />reliable car towing service.
                </p>
            </div>
        </div>
        <div className='klt_aboutUs_row2'>
            <div className='klt_aboutUs_col'>
                <div className='klt_boxes'>
                    <h3>Affordable Prices</h3>
                    <p>Quality towing service shouldn't break the bank. We offer competitive pricing without compromising on the quality of service.</p>
                </div>
                <div className='klt_boxes'>
                    <h3>Fast and efficient</h3>
                    <p> We pride ourselves on our rapid response times. When you call us, you can expect a friendly dispatcher and a tow truck at your location in no time.</p>
                </div>
                <div className='klt_boxes'>
                    <h3>Professional staff</h3>
                    <p>Our drivers are licensed, insured, and highly skilled. They'll treat your vehicle with the utmost care and respect.</p>
                </div>
            </div>
        </div>
        <div className='klt_spacer'></div>
        <div className='klt_about_row padd'>
            <div className='klt_about_col'>
                <h2 className='klt_colored_text'>
                    WHY US
                </h2>
                <h3>
                    24/7 towing services is what we do
                </h3>
                <p>
                Towing a car can be difficult to attempt on your own if you do not have the right equipment or a powerful enough Towing Vehicle, so do not risk further damage to your car by trying to tow it yourself.
                </p>
                <div className='klt_spacer'></div>
                <button className='klt_cta_btn'>Call us now</button>
            </div>
            <div className='klt_about_col'>
                <img src={image_2} alt='man-towing-car' />
            </div>
        </div>
        <InfoBar />
    </div>
  )
}

export default About
