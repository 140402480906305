import { useParams } from "react-router-dom"
import allBlogs from "./allBlogs"
import Sidebar from "./Sidebar"
import BlogPostSchema from "../../../schema/BlogPostSchema"

const BlogPost = () => {

    const { id } = useParams()
    
    // const blog = allBlogs.find(Blog => {
    //     return Blog.title.replace(/\s+/g, '-') === id
    // })

    const blog = allBlogs.find(blog => blog.title.replace(/\s+/g, '-') === id);
    console.log(blog)
    // console.log(blog)

    return (
        <div style={{
            maxWidth: '100%',
        }}>
            <div className="bts_blog-post" style={{
                maxWidth: '1240px',
                margin: '0 auto'
            }}>
                <div style={{
                    paddingTop: '100px'
                }}>
                    {/* <div className="bts-spacer-x1"></div> */}
                    <div className="bts_blog-post_head">
                        <h1>{blog.title}</h1>
                        <BlogPostSchema
                            title={blog.title}
                            description={blog.content.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 60) + (blog.content.replace(/<\/?[^>]+(>|$)/g, "").length > 60 ? "..." : "")}
                            datePublished={blog.date}
                            authorName={blog.author}
                            imageUrl={blog.img_url}
                            url={`/blog/${blog.title.replace(/\s+/g, '-')}`}
                        />
                        <div>
                            <div className="bts_blog-post_author_cont">
                                <span>
                                    <img className="bts_blog-post_author" src={blog.author_img} alt={blog.author} />
                                </span>
                                <p>
                                {blog.author}
                                </p>
                            </div>
                            <p>Published on: <span>{blog.date}</span></p>
                        </div>
                        <img className="bts_blog-post_img" src={blog.img_url} alt={blog.title} />
                        <div className="bts-spacer-x1"></div>
                        <div dangerouslySetInnerHTML={{ __html: blog.content }} className="bts_blog-post_content" >
                            {/* <p dangerouslySetInnerHTML={blog.content} ></p> */}
                        </div>
                    </div>
                </div>
                <div>
                    <Sidebar />
                </div>
            </div>
        </div>
    )
}

export default BlogPost