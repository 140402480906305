import React from 'react'
import "./testimonials.css"
import { FaStar } from 'react-icons/fa6'

const Testimonial = () => {
  return (
    <div className='klt_testimonial'>
      <div className='klt_testimonial_row'>
            <div className='klt_testimonial_col'>
                <h2 className='klt_colored_text'>
                    WHAT CLIENTS SAY
                </h2>
                <h3>
                    We transport your vehicle <br/>safely and efficiently
                </h3>
                <p>
                    We are a well-equipped towing service available to help with <br/>towing a car 24/7 we provide a fast, affordable, friendly, and <br />reliable car towing service.
                </p>
            </div>
        </div>
        <div className='klt_testimonial_row'>
          <div className='klt_testimonial_col'>
            <div>
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
            <p>
            KL Towing came to my rescue when my car broke down on the highway. Their prompt response and professional service got me back on the road in no time. Highly recommended! - <b>Sarah W</b>.
            </p>
          </div>
          <div className='klt_testimonial_col'>
            <div>
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
            <p>
            I was in a motorcycle accident and KL Towing was there within minutes. They handled everything with care and made a stressful situation much easier to deal with. Thank you! - <b>Mark D</b>
            </p>
          </div>
          <div className='klt_testimonial_col'>
            <div>
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
            <p>
            KL Towing provided exceptional service when I needed help with a flat tire. They arrived promptly, assessed the situation, and had me back on the road in no time. - <br /><b>Jason M</b>.
            </p>
          </div>
        </div>
    </div>
  )
}

export default Testimonial
