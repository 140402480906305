import React from 'react'
import './about.css'
import image_1 from './assets/man-towing-car.png'
import image_2 from './assets/loose-tyre.png'
import { FaCheck } from 'react-icons/fa6'

const About = () => {
  return (
    <div className='klt_about'>
      <div className='klt_about_row'>
        <div className='klt_about_col'>
            <img src={image_1} alt='man-towing-car' />
        </div>
        <div className='klt_about_col'>
            <h2 className='klt_colored_text'>
                WHY US
            </h2>
            <h3>
                24/7 towing services is what we do
            </h3>
            <p>
            KL Towing offers 24/7 roadside assistance, including prompt and reliable towing for cars, motorcycles, and trucks.
            </p>
            <div className='klt_spacer'></div>
            <div>
                <div>
                    <FaCheck color='white' size={20} />
                </div>
                <div>
                    <h3>
                        Quick Response time
                    </h3>
                    <p>
                    We pride ourselves on our rapid response times. When you call us, you can expect a friendly dispatcher and a tow truck at your location in no time.
                    </p>
                </div>
            </div>
            <div className='klt_spacer'></div>
            <div>
                <div>
                    <FaCheck color='white' size={20} />
                </div>
                <div>
                    <h3>
                        Fast, safe and affordable
                    </h3>
                    <p>
                    Quality towing service shouldn't break the bank. We offer competitive pricing without compromising on the quality of service.
                    </p>
                </div>
            </div>
        </div>
      </div>
      <div className='klt_spacer'></div>
      <div className='klt_spacer'></div>
      <div className='klt_spacer'></div>
      <div className='klt_about_row'>
        <div className='klt_about_col'>
            <h2 className='klt_colored_text'>
                WHY US
            </h2>
            <h3>
                24/7 towing services is what we do
            </h3>
            <p>
            Towing a car can be difficult to attempt on your own if you do not have the right equipment or a powerful enough Towing Vehicle, so do not risk further damage to your car by trying to tow it yourself.
            </p>
            <div className='klt_spacer'></div>
            <a href='tel:+14704756564'>
                <button className='klt_cta_btn major'>Call us now</button>
            </a>
        </div>
      <div className='klt_about_col'>
        <img src={image_2} alt='man-towing-car' />
      </div>
      </div>
    </div>
  )
}

export default About
