import { Link } from "react-router-dom"
import "./drawer.css"

const MobileMenu = ({ close }) => {

    return (
        <div className="bts_mobile_menu">
            <ul className="bts_mobile_navitems">
                <Link to="/" onClick={close}>
                    <li className="bts_mobile_navitem">Home</li>
                </Link>
                <Link to="/about" onClick={close} >
                    <li className="bts_mobile_navitem">About Us</li>
                </Link>
                <Link to="/gallery" onClick={close} >
                    <li className="bts_mobile_navitem">Gallery</li>
                </Link>
                <Link to="/contact" onClick={close}>
                    <li className="bts_mobile_navitem">Contact Us</li>
                </Link>
                <Link to="/blog" onClick={close}>
                    <li className="bts_mobile_navitem">Blog</li>
                </Link>
            </ul>
        </div>
    )
}

export default MobileMenu