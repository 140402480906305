import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './navbar.css'
import MenuDrawer from '../mobile/MenuDrawer'
import Logo from './assets/kl-towing-logo.png'

const Navbar = () => {

  const [ open, setOpen ] = useState("bts_slide_in")

    const handle_click_open = () => {
        setOpen("bts_slide_out")
    }

    const handle_click_close = () => {
        setOpen("bts_slide_in slide_in")
    }

  return (
    <div className='klt_navbar'>
      <div className='klt_navbar_row'>
        <div className='klt_navbar_col'>
            <img className='kl-logo' src={Logo} alt="kl-towing-logo" />
            {/* <h1>KLTowing</h1> */}
        </div>
        <div className='klt_navbar_col'>
            <ul className='klt_navitems'>
                <li className='klt_navitem'>
                  <Link to='/'>
                    Home
                  </Link>
                </li>
                <li className='klt_navitem'>
                  <Link to='/about'>
                    About
                  </Link>
                </li>
                <li className='klt_navitem'>
                  <Link to='/gallery'>
                    Gallery
                  </Link>
                </li>
                <li className='klt_navitem'>
                  <Link to="/contact">
                      Contact Us
                  </Link>
                </li>
                <li className='klt_navitem'>
                  <Link to="/blog">
                    Blog
                  </Link>
                </li>
                <a href='tel:+14704756564'>
                  <li className='klt_button'>
                    Call 24/7: +1 470 475 6564
                  </li>
                </a>
            </ul>
        </div>
        <div className="bts_navbar_col bts_hamburger_cont" onClick={handle_click_open}>
            <svg className="bts_hamburger" xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30">
                <path d="M120-240v-60h720v60H120Zm0-210v-60h720v60H120Zm0-210v-60h720v60H120Z"></path>
            </svg>
        </div>
        {open === "bts_slide_out" ?
        <div className="bts_close_cont" onClick={handle_click_close}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="30" 
                height="30" 
                fill="currentColor" 
                className="bi bi-x-lg" 
                viewBox="0 0 16 16"
            >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
            </svg>
        </div> : ""
        }
        <MenuDrawer isOpen={open} close={handle_click_close} />
      </div>
    </div>
  )
}

export default Navbar
