import React from 'react'
import "./gallery.css"
import MasonryComponent from '../../components/masonry/MasonryComponent'
import { createTheme, ThemeProvider } from '@mui/material'

const Gallery = () => {

    const theme = createTheme();

  return (
    <div className='kl-gallery'>
        <div className='kl-gallery_row'>
            <div className='kl-gallery_col'>
                <h2 className='klt_colored_text'>
                    GALLERY
                </h2>
                <h3>
                    Our Trucks in action
                </h3>
            </div>
        </div>
        <div className='kl-gallery_row'>
            <div className='kl-gallery_col'>
                <ThemeProvider theme={theme} >
                    <MasonryComponent />
                </ThemeProvider>
            </div>
        </div>
    </div>
  )
}

export default Gallery