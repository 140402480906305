const categoryData = [
    // "All",
    "Web Design",
    "UI-UX Design",
    "Web Development", 
    "Digital Marketing",
    "Social Media",
    "IoT"
]

export default categoryData