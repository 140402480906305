import React from 'react';
import { JsonLd } from 'react-schemaorg';

const AboutSchema = () => (
  <JsonLd>
    {{
      "@context": "https://schema.org",
      "@type": "AboutPage",
      "mainEntity": {
        "@type": "Organization",
        "name": "KL Towing Service",
        "url": "https://www.kltowingservice.com/about",
        "logo": "https://www.kltowingservice.com/logo.png",
        "description": "KL Towing Service has been providing reliable and professional towing services in Lawrenceville, GA, and surrounding areas for over 10 years. Our experienced team is available 24/7 to assist with all your roadside needs."
      }
    }}
  </JsonLd>
);

export default AboutSchema;