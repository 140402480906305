import "../blog.css"
import RecentCard from "./RecentCard"
import TrendingCard from "./TrendingCard"
import allBlogs from "./allBlogs"

const Sidebar = () => {

    const trending = allBlogs.filter(trendingBlogs => {
        return trendingBlogs.trending === true;
    })

    const recents = allBlogs.filter(recentBlogs => {
        return recentBlogs.recent === true;
    })

    return (
        <div className="bts_sidebar bts_sidebar_col">
            <div className="bts_trending">
                <h2>Trending Blogs</h2>
                {trending.map((trendingBlog) => (
                    <TrendingCard
                        key={trendingBlog.id}
                        title={trendingBlog.title} 
                        date={trendingBlog.date} 
                        id={trendingBlog.title.replace(/\s+/g, '-')}
                        // img={trendingBlog.img}
                    />
                ))}
            </div>
            <div className="bts_recent_posts">
                <h2>Recent Posts</h2>
                <div className="bts_recent-card_cont">
                {recents.map((recentBlog) => (
                    <RecentCard
                        key={recentBlog.id}
                        img={recentBlog.img_url}
                        title={recentBlog.title}
                        id={recentBlog.title.replace(/\s+/g, '-')}
                    />
                ))}
                </div>
            </div>
        </div>
    )
}

export default Sidebar