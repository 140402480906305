import categoryData from "./categorydata"
import { AuthorImg, BlogImage_1, Blog_Image_2, Blog_Image_1, Blog_Image_3, Blog_Image_4, Blog_Image_5, Blog_Image_6 } from "../assets/assets"
import Blog_5 from "./blogcontent/Blog_5"
import Blog_2 from "./blogcontent/Blog_2"
import Blog_1 from "./blogcontent/Blog_1"
import Blog_3 from "./blogcontent/Blog_3"
import Blog_4 from "./blogcontent/Blog_4"
import Blog_6 from "./blogcontent/Blog_6"

const allBlogs = [
    {
        "id": 1,
        "img_url": Blog_Image_1,
        "recent": true,
        "img": "bts_blog_img_1",
        "category": categoryData[2],
        "title": "The Ultimate Guide to Choosing the Best Towing Service for Your Vehicle",
        "time": "5 mins to read",
        "author": "Binatec",
        "author_img": AuthorImg,
        "trending": false,
        "date": "August 16, 2024",
        "content": Blog_1
    },
    {
        "id": 2,
        "img_url": Blog_Image_2,
        "recent": true,
        "img": "bts_blog_img_2",
        "category": categoryData[1],
        "title": "Top 5 Towing Mistakes to Avoid: What You Need to Know",
        "time": "5 mins to read",
        "author": "Muyiwa",
        "trending": true,
        "author_img": AuthorImg,
        "date": "August 3, 2023",
        "content": Blog_2
    },
    {
        "id": 3,
        "img_url": Blog_Image_3,
        "recent": false,
        "img": "bts_blog_img_3",
        "category": categoryData[4],
        "title": "How to Prepare Your Car for Safe and Hassle-Free Towing",
        "time": "5 mins to read",
        "author": "Muyiwa",
        "author_img": AuthorImg,
        "trending": false,
        "date": "August 3, 2023",
        "content": Blog_3
    },
    {
        "id": 4,
        "img_url": Blog_Image_4,
        "recent": true,
        "img": "bts_blog_img_4",
        "category": categoryData[4],
        "title": "Emergency Towing: What to Do When Your Vehicle Breaks Down",
        "time": "5 mins to read",
        "author": "Binatec",
        "author_img": AuthorImg,
        "trending": true,
        "date": "August 3, 2023",
        "content": Blog_4
    },
    {
        "id": 5,
        "img_url": Blog_Image_5,
        "recent": false,
        "img": "bts_blog_img_5",
        "category": categoryData[4],
        "title": "Understanding Towing Costs: What’s Included and How to Save",
        "time": "5 mins to read",
        "author": "Binatec",
        "author_img": AuthorImg,
        "trending": false,
        "date": "August 3, 2023",
        "content": Blog_5
    },
    {
        "id": 6,
        "img_url": Blog_Image_6,
        "recent": true,
        "img": "bts_blog_img_6",
        "category": categoryData[2],
        "title": "10 Essential Tools Every Tow Truck Should Have Onboard",
        "time": "5 Mins to read",
        "author": "Binatec",
        "author_img": AuthorImg,
        "trending": true,
        "date": "August 3, 2023",
        "content": Blog_6
    },
    {
        "id": 7,
        "img_url": BlogImage_1,
        "recent": true,
        "img": "bts_blog_img1",
        "category": categoryData[2],
        "title": "Winter Towing Tips: How to Stay Safe on Icy Roads",
        "time": "5 Mins to read",
        "author": "Binatec",
        "author_img": AuthorImg,
        "trending": true,
        "date": "August 3, 2023",
        "content": Blog_6
    },
    {
        "id": 8,
        "img_url": BlogImage_1,
        "recent": true,
        "img": "bts_blog_img1",
        "category": categoryData[2],
        "title": "Winter Towing Tips: How to Stay Safe on Icy Roads",
        "time": "5 Mins to read",
        "author": "Binatec",
        "author_img": AuthorImg,
        "trending": true,
        "date": "August 3, 2023",
        "content": Blog_5
    }

]

export default allBlogs