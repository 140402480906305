import React from 'react'
import './infoBar.css'
import { FaPhone } from 'react-icons/fa6'

const InfoBar = () => {
  return (
    <div className='klt_info_bar'>
        <a href='tel:+14704756564'>
            <div>
                <p>24/7 EMERGENCY TOWING</p>
                <p className='klt_info_box_phone'>+1-470-475-6564</p>
            </div>
            <p className='klt_info_bar_cta'>
                <span>
                    <FaPhone />
                </span>
                CALL NOW!!
            </p>
        </a>
    </div>
  )
}

export default InfoBar
