// src/schema/ContactSchema.js
import React from 'react';
import { JsonLd } from 'react-schemaorg';

const ContactSchema = () => (
  <JsonLd>
    {{
      "@context": "https://schema.org",
      "@type": "ContactPage",
      "mainEntity": {
        "@type": "Organization",
        "name": "KL Towing Service",
        "url": "https://www.kltowingservice.com/contact",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+1-470-475-6564",
          "contactType": "Customer Service",
          "areaServed": "US",
          "availableLanguage": "English"
        }
      }
    }}
  </JsonLd>
);

export default ContactSchema;
