import { Link } from "react-router-dom"
import "../blog.css"

const BlogCard = ({ img, title, date, author, id }) => {
    return (
        <Link to={`/blog/${id}`} className="bts_blog-card">
            <div className={`bts_blog-card_img ${img}`}></div>
            <div className="bts_blog-card_detail">
                <h3>{title}</h3>
                <div>
                    <p>{date}</p>
                    {/* <p>{author}</p> */}
                </div>
            </div>
        </Link>
    )
}

export default BlogCard

// <Link to={`/blog/category/${category.replace(/\s+/g, '-')}`} ></Link>