import React from 'react'
import Main from './sections/Main'
import Sidebar from './sections/Sidebar'

const Blog = () => {
  return (
    <div className='klt_blog'>
        <div className='klt_blog_row'>
            <div className='klt_blog_col' style={{ 
                height: '350px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '58px',
                fontWeight: '500',
                paddingTop: '50px'
             }}>
                {/* <h2 className='klt_contact_text'>
                    ABOUT US
                </h2> */}
                <h3>
                    Blog
                </h3>
            </div>
            <div className="bts_blog">
            <div>
                <Main />
            </div>
            <div>
                <Sidebar />
            </div>
        </div>
        </div>
    </div>
  )
}

export default Blog
