// src/schema/HomeSchema.js
import React from 'react';
import { JsonLd } from 'react-schemaorg';

const HomeSchema = () => (
  <JsonLd>
    {{
      "@context": "https://schema.org",
      "@type": "TowingService",
      "name": "KL Towing Service",
      "image": "https://www.kltowingservice.com/logo.png",
      "url": "https://www.kltowingservice.com",
      "telephone": "+1-470-475-6564",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "267 Langley Drive PMB 1399",
        "addressLocality": "Lawrenceville",
        "addressRegion": "GA",
        "postalCode": "30046",
        "addressCountry": "US"
      },
      "openingHours": "24/7",
      "priceRange": "$$",
      "description": "KL Towing Service provides fast and reliable towing services across Lawrenceville, Grayson, Stone Mountain, Loganville, and Duluth in GA. We offer 24/7 roadside assistance and towing services.",
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "33.8025547",
        "longitude": "-83.93103"
      },
      "sameAs": [
        "https://www.facebook.com/people/KL-Towing-Service/61563748572924/",
        "https://x.com/KLTowingService",
        "https://www.linkedin.com/company/kl-towing-service/",
        "https://www.google.com/maps/place/KL+Towing+Service/@33.8025547,-83.93103,11z/data=!4m2!3m1!1s0x0:0x873a457e875b38a1?sa=X&ved=1t:2428&ictx=111"
      ]
    }}
  </JsonLd>
);

export default HomeSchema;
