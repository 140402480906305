import React from 'react'
import "./faq.css"
import { FaQuestion } from 'react-icons/fa6'

const Faq = () => {
  return (
    <div className='klt_faq'>
      <div className='klt_testimonial_row'>
            <div className='klt_testimonial_col'>
                <h2 className='klt_colored_text'>
                    FAQ
                </h2>
                <h3>
                    Popular questions <br/>answered
                </h3>
                <p>
                Explore our frequently asked questions to find quick answers to common inquiries about our services,<br /> response times, pricing, and more.
                </p>
            </div>
        </div>
        <div className='klt_spacer'></div>
        <div className='klt_spacer'></div>
        <div className='klt_faq_row'>
            <div className='klt_faq_col'>
                <div>
                    <div>
                        <FaQuestion color='white' size={20} className='klt_question_mark' />
                    </div>
                    <div>
                        <h3>How quickly can KL Towing respond to my roadside emergency?</h3>
                        <p>At KL Towing, we prioritize rapid response times. Our team strives to reach your location within 15-20 minutes of your call.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <FaQuestion color='white' size={20} className='klt_question_mark' />
                    </div>
                    <div>
                        <h3>Are your towing services available 24/7?</h3>
                        <p>Yes, KL Towing operates round the clock, 365 days a year. Whether it's the middle of the night or a holiday weekend, you can count on us to be there when you need us most.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <FaQuestion color='white' size={20} className='klt_question_mark' />
                    </div>
                    <div>
                        <h3>What types of vehicles can KL Towing tow?</h3>
                        <p> We specialize in towing a wide range of vehicles, including cars, motorcycles, trucks, RVs, and more. We have the expertise and equipment to handle any type of vehicle safely.</p>
                    </div>
                </div>
            </div>
            <div className='klt_faq_col'>
                <div>
                    <div>
                        <FaQuestion color='white' size={20} className='klt_question_mark' />
                    </div>
                    <div>
                        <h3>How much will it cost to tow my vehicle?</h3>
                        <p> The cost of towing services can vary depending on factors such as distance, vehicle type, and the nature of the emergency.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <FaQuestion color='white' size={20} className='klt_question_mark' />
                    </div>
                    <div>
                        <h3>Do you provide assistance for roadside emergencies other than towing?</h3>
                        <p>Yes, KL Towing offers a range of roadside assistance solutions, including jump-starts, tire changes, lockout assistance etc.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <FaQuestion color='white' size={20} className='klt_question_mark' />
                    </div>
                    <div>
                        <h3>How do I contact KL Towing in case of an emergency?</h3>
                        <p>To request immediate assistance, simply dial our hotline at [insert phone number]. Our friendly dispatchers are available 24/7 to take your call, gather necessary information, and dispatch a tow truck to your location promptly.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='klt_faq_row'>
            <div className='klt_faq_col'>
                <p>Did you find your answer?   
                    <a href='tel:+17033385304'>
                          Contact us now
                    </a>
                </p>
            </div>
        </div>
    </div>
  )
}

export default Faq
