import React from 'react';
import { JsonLd } from 'react-schemaorg';

const BlogPostSchema = ({ title, description, datePublished, authorName, imageUrl, url }) => (
  <JsonLd>
    {{
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "headline": title,
      "description": description,
      "datePublished": datePublished,
      "author": {
        "@type": "Person",
        "name": authorName
      },
      "image": imageUrl,
      "url": url,
      "publisher": {
        "@type": "Organization",
        "name": "KL Towing Service",
        "logo": {
          "@type": "ImageObject",
          "url": "https://www.kltowingservice.com/logo.png"
        }
      }
    }}
  </JsonLd>
);

export default BlogPostSchema;