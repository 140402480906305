import { Link } from "react-router-dom"
import "../blog.css"

const TrendingCard = ({ title, date, id }) => {
    return (
        <Link to={`/blog/${id}`}>
            <div className={`bts_trending-card`}>
                <h3>{title}</h3>
                <p>{date}</p>
            </div>
        </Link>
    )
}

export default TrendingCard