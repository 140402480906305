// import { Link } from "react-router-dom"
import BlogCard from "./BlogCard"
// import CategoryHeader from "./Category"
import allBlogs from "./allBlogs"

const Main = () => {
    return (
        <>
        {/* <CategoryHeader /> */}
        <div className="bts-spacer-x1"></div>
        <div className="bts_blogs_main">
            {allBlogs.map((blog) => (
                <BlogCard 
                    key={blog.id}
                    img={blog.img}
                    category={blog.category}
                    title={blog.title}
                    date={blog.time}
                    author={blog.author}
                    id={blog.title.replace(/\s+/g, '-')}
                />
            ))}
        </div>
        </>
    )
}

export default Main