import React from 'react'
import "./footer.css"


const Footer = () => {
  return (
    <div className='klt_footer'>
      <span>
        <p>Designed by </p>
        <a href='https://www.binatecagency.com'>Binatec Agency</a>
      </span>
    </div>
  )
}

export default Footer
