import { Link } from "react-router-dom"

const RecentCard = ({ img, title, id }) => {
    return (
        <Link to={`/blog/${id}`} >
            <div className="bts_recent-card">
                <img className="bts_recent-card_img" src={img} alt={title} />
                <h4>{title}</h4>
            </div>
        </Link>
    )
}

export default RecentCard