import React from 'react'
import { FaSquareArrowUpRight } from 'react-icons/fa6'
import "./services.css"

const Services = () => {
  return (
    <div className='klt_services'>
        <div className='klt_services_row'>
            <div className='klt_services_col'>
                <h2 className='klt_colored_text'>
                    OUR SERVICES
                </h2>
                <h3>
                    We transport your vehicle <br/>safely and efficiently
                </h3>
                <p>
                    We are a well-equipped towing service available to help with <br/>towing a car 24/7 we provide a fast, affordable, friendly, and <br />reliable car towing service.
                </p>
            </div>
        </div>
        <div className='klt_services_row'>
            <div className='klt_services_col'>
                <img src='' alt='' />
                <h3>24/7 Emergency towing service</h3>
                <p>We are a well-equipped towing service available to help with towing a car 24/7 we provide a fast, affordable, friendly, and reliable car towing service. </p>
            </div>
            <div className='klt_services_col red_box'>
                <img src='' alt='' />
                <h3>25% off emergency road service</h3>
                <p>Favorable offer for new clients.Quick response, reliable assistance, and now, even more affordable. Don't let a breakdown ruin your day.</p>
                <button className='klt_services_cta'><a className='link' href='tel:+14704756564'>CALL NOW! <span><FaSquareArrowUpRight /></span></a></button>
            </div>
        </div>
        <div className='klt_spacer'></div>
        <div className='klt_services_row'>
            <div className='klt_services_col'>
                <img src='' alt='' />
                <h3>Car towing service</h3>
                <p> Our fleet of modern tow trucks and skilled operators ensures that your vehicle is safely transported to its destination, giving you peace of mind during stressful situations.</p>
            </div>
            <div className='klt_services_col'>
                <img src='' alt='' />
                <h3>Motorcycle towing</h3>
                <p>Our specialized motorcycle towing service is tailored to handle the unique needs of two-wheeled vehicles. With our equipment and expertise, we'll transport your motorcycle safely.</p>
            </div>
            <div className='klt_services_col'>
                <img src='' alt='' />
                <h3>Accident Assistance</h3>
                <p>From minor to major collisions, Our trained professionals will quickly and efficiently remove your vehicle from the scene, minimizing further damage and ensuring your safety. </p>
            </div>
        </div>
    </div>
  )
}

export default Services
