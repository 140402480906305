import React from 'react'
import { Box, Paper, useMediaQuery } from '@mui/material'
import { Masonry } from '@mui/lab'
import { image_1, image_2, image_3, image_4, image_5, image_6, image_7, image_8, image_9, image_10, image_11, image_12, image_13, image_14, image_15, image_16 } from './assets/assets.js'

const MasonryComponent = () => {

    const background = [
        image_1, image_2, image_3, image_4, image_5, image_6, image_7, image_8, image_9, image_10, image_11, image_12, image_13, image_14, image_15, image_16
    ]

    // Define breakpoints for responsive columns
    const isXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const isSm = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));
    const isMd = useMediaQuery((theme) => theme.breakpoints.between('md', 'lg'));
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    let columns;
    if (isXs) {
        columns = 1;
    } else if (isSm) {
        columns = 2;
    } else if (isMd) {
        columns = 3;
    } else if (isLg) {
        columns = 4;
    }

  return (
    <Box sx={{ 
                width: "100%", 
                boxSizing: "border-box", 
                paddingLeft: { 
                    xs: '16px', 
                    sm: '32px', 
                    md: '64px', 
                    lg: '80px' 
                }, 
                paddingRight: { 
                    xs: '16px', 
                    sm: '32px', 
                    md: '64px', 
                    lg: '80px' 
                }, 
            }}>
        <Masonry columns={columns} spacing={1}>
            {background.map((background, index) => (
                <Paper 
                    key={index} 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        height: '400px',
                        border: '1px solid',
                        backgroundImage: `url(${background})`,
                        backgroundSize: 'cover'
                    }} 
                />
            ))}
        </Masonry>
    </Box>
  )
}

export default MasonryComponent